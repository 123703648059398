export const countryPhotoTypes = {
  "United States": [
    "Passport",
    "Visa",
    "DV lottery",
    "Green card"
  ],
  "United Kingdom": [
    "Driving Licence",
    "Passport",
    "Visa",
    "Passport photo for bus pass"
  ],
  "Ireland": [
    "Passport",
    "SPSV Driver Licence - Taxi Licence",
    "Visa"
  ],
  "Australia": [
    "Passport",
    "Taxi Cab-Private Hire Vehicle ID Card",
    "Visa"
  ],
  "Singapore": [
    "ID Card",
    "Passport",
    "Visa"
  ],
  "New Zealand": [
    "Passport",
    "Visa"
  ],
  "European Union": [
    "Passport"
  ],
  "Schengen": [
    "Visa"
  ],
  "Albania": [
    "Passport"
  ],
  "Argentina": [
    "Passport"
  ],
  "Austria": [
    "Passport"
  ],
  "Bangladesh": [
    "Passport"
  ],
  "Belarus": [
    "Passport",
    "Visa"
  ],
  "Belgium": [
    "Passport"
  ],
  "Brazil": [
    "Passport",
    "Visa",
    "Passport or Visa"
  ],
  "Bulgaria": [
    "Passport"
  ],
  "Cambodia": [
    "Passport"
  ],
  "Canada": [
    "Passport",
    "Citizenship",
    "Permanent Resident",
    "Visa"
  ],
  "China": [
    "Passport",
    "Visa"
  ],
  "Colombia": [
    "Passport"
  ],
  "Congo": [
    "Passport",
    "Visa"
  ],
  "Costa Rica": [
    "Passport"
  ],
  "Croatia": [
    "Passport"
  ],
  "Cyprus": [
    "Passport"
  ],
  "Czech Republic": [
    "Passport"
  ],
  "Denmark": [
    "Passport"
  ],
  "Estonia": [
    "Passport"
  ],
  "Finland": [
    "Passport",
    "Visa"
  ],
  "France": [
    "Passport",
    "Visa"
  ],
  "Germany": [
    "Passport"
  ],
  "Ghana": [
    "Passport"
  ],
  "Greece": [
    "Passport"
  ],
  "Hong Kong": [
    "Passport"
  ],
  "Hungary": [
    "Passport"
  ],
  "India": [
    "Passport 5.1 x 5.1 cm",
    "Passport 3.5 x 3.5 cm",
    "Visa",
    "Overseas Citizenship 51 x 51 mm",
    "Overseas Citizenship",
    "PAN Card"
  ],
  "Indonesia": [
    "Passport"
  ],
  "Iran": [
    "Passport"
  ],
  "Israel": [
    "Passport"
  ],
  "Italy": [
    "Passport"
  ],
  "Jamaica": [
    "Passport"
  ],
  "Japan": [
    "Passport",
    "Visa",
    "Driving License",
    "International Driving License",
    "Alien Registration-Professional"
  ],
  "Kenya": [
    "Passport"
  ],
  "Lebanon": [
    "Passport"
  ],
  "Macao": [
    "Passport"
  ],
  "Malaysia": [
    "Passport",
    "Visa"
  ],
  "Mexico": [
    "Passport"
  ],
  "Morocco": [
    "Passport"
  ],
  "Myanmar": [
    "Passport",
    "Visa"
  ],
  "Netherlands": [
    "Passport"
  ],
  "Nigeria": [
    "Passport"
  ],
  "Norway": [
    "Passport"
  ],
  "Pakistan": [
    "Passport"
  ],
  "Panama": [
    "Passport"
  ],
  "Peru": [
    "Passport"
  ],
  "Philippines": [
    "Passport"
  ],
  "Poland": [
    "Passport"
  ],
  "Portugal": [
    "Passport"
  ],
  "Romania": [
    "Passport"
  ],
  "Russia": [
    "Passport"
  ],
  "Saudi Arabia": [
    "Passport",
    "Visa"
  ],
  "South Africa": [
    "Passport"
  ],
  "South Korea": [
    "Passport"
  ],
  "Spain": [
    "Passport"
  ],
  "Sweden": [
    "Passport"
  ],
  "Switzerland": [
    "Passport"
  ],
  "Syria": [
    "Passport"
  ],
  "Taiwan": [
    "Passport"
  ],
  "Tanzania": [
    "Passport",
    "Visa"
  ],
  "Thailand": [
    "Passport",
    "Visa"
  ],
  "Trinidad and Tobago": [
    "Passport"
  ],
  "Turkey": [
    "Passport",
    "Visa"
  ],
  "Ukraine": [
    "Passport"
  ],
  "Uzbekistan": [
    "Passport"
  ],
  "Venezuela": [
    "Passport"
  ],
  "Vietnam": [
    "Passport",
    "Visa"
  ],
  "Zimbabwe": [
    "Passport"
  ]
};

export const photoRequirementsDetailed = {
  "United States-Passport": {
    "ImageSize": "2x2 in",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "1000x1000",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/UnitedStates.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/UnitedStates-Passport_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/UnitedStates-Passport_d.jpeg",
    "hair_to_chin_perc": 0.685
  },
  "United States-Visa": {
    "ImageSize": "2x2 in",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "600x600",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/UnitedStates.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/UnitedStates-Visa_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/UnitedStates-Visa_d.jpeg",
    "hair_to_chin_perc": 0.685
  },
  "United States-DV lottery": {
    "ImageSize": "2x2 in",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "600x600",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/UnitedStates.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/UnitedStates-DVlottery_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/UnitedStates-DVlottery_d.jpeg",
    "hair_to_chin_perc": 0.685
  },
  "United States-Green card": {
    "ImageSize": "2x2 in",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "1000x1000",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/UnitedStates.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/UnitedStates-Greencard_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/UnitedStates-Greencard_d.jpeg",
    "hair_to_chin_perc": 0.685
  },
  "United Kingdom-Driving Licence": {
    "ImageSize": "35x45 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "826x1062",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/UnitedKingdom.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/UnitedKingdom-DrivingLicence_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/UnitedKingdom-DrivingLicence_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "United Kingdom-Passport": {
    "ImageSize": "35x45 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "826x1062",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/UnitedKingdom.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/UnitedKingdom-Passport_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/UnitedKingdom-Passport_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "United Kingdom-Visa": {
    "ImageSize": "35x45 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "826x1062",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/UnitedKingdom.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/UnitedKingdom-Visa_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/UnitedKingdom-Visa_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "United Kingdom-Passport photo for bus pass": {
    "ImageSize": "35x45 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "256x330",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/UnitedKingdom.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/UnitedKingdom-Passportphotoforbuspass_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/UnitedKingdom-Passportphotoforbuspass_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Ireland-Passport": {
    "ImageSize": "35x45 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "826x1063",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Ireland.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Ireland-Passport_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Ireland-Passport_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Ireland-SPSV Driver Licence - Taxi Licence": {
    "ImageSize": "70x100 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "1653x2362",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Ireland.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Ireland-SPSVDriverLicence-TaxiLicence_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Ireland-SPSVDriverLicence-TaxiLicence_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Ireland-Visa": {
    "ImageSize": "35x45 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "826x1063",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Ireland.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Ireland-Visa_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Ireland-Visa_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Australia-Passport": {
    "ImageSize": "35x45 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "826x1062",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Australia.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Australia-Passport_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Australia-Passport_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Australia-Taxi Cab-Private Hire Vehicle ID Card": {
    "ImageSize": "65x65 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "1535x1535",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Australia.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Australia-TaxiCab-PrivateHireVehicleIDCard_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Australia-TaxiCab-PrivateHireVehicleIDCard_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Australia-Visa": {
    "ImageSize": "35x45 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "826x1062",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Australia.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Australia-Visa_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Australia-Visa_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Singapore-ID Card": {
    "ImageSize": "35x45 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "400x514",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Singapore.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Singapore-IDCard_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Singapore-IDCard_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Singapore-Passport": {
    "ImageSize": "35x45 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "400x514",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Singapore.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Singapore-Passport_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Singapore-Passport_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Singapore-Visa": {
    "ImageSize": "35x45 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "400x514",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Singapore.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Singapore-Visa_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Singapore-Visa_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "New Zealand-Passport": {
    "ImageSize": "35x45 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "1800x2400",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/NewZealand.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/NewZealand-Passport_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/NewZealand-Passport_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "New Zealand-Visa": {
    "ImageSize": "35x45 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "1800x2400",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/NewZealand.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/NewZealand-Visa_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/NewZealand-Visa_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "European Union-Passport": {
    "ImageSize": "35x45 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "826x1062",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/EuropeanUnion.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/EuropeanUnion-Passport_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/EuropeanUnion-Passport_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Schengen-Visa": {
    "ImageSize": "35x45 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "826x1062",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Schengen.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Schengen-Visa_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Schengen-Visa_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Albania-Passport": {
    "ImageSize": "40x50 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "944x1181",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Albania.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Albania-Passport_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Albania-Passport_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Argentina-Passport": {
    "ImageSize": "1.5x1.5 in",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "900x900",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Argentina.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Argentina-Passport_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Argentina-Passport_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Austria-Passport": {
    "ImageSize": "35x45 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "826x1062",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Austria.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Austria-Passport_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Austria-Passport_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Bangladesh-Passport": {
    "ImageSize": "45x55 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "1062x1299",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Bangladesh.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Bangladesh-Passport_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Bangladesh-Passport_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Belarus-Passport": {
    "ImageSize": "40x50 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "944x1181",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Belarus.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Belarus-Passport_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Belarus-Passport_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Belarus-Visa": {
    "ImageSize": "35x45 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "826x1062",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Belarus.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Belarus-Visa_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Belarus-Visa_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Belgium-Passport": {
    "ImageSize": "35x45 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "826x1062",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Belgium.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Belgium-Passport_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Belgium-Passport_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Brazil-Passport": {
    "ImageSize": "2x2 in",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "600x600",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Brazil.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Brazil-Passport_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Brazil-Passport_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Brazil-Visa": {
    "ImageSize": "2x2 in",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "600x600",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Brazil.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Brazil-Visa_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Brazil-Visa_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Brazil-Passport or Visa": {
    "ImageSize": "50x70 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "590x827",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Brazil.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Brazil-PassportorVisa_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Brazil-PassportorVisa_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Bulgaria-Passport": {
    "ImageSize": "35x45 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "826x1062",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Bulgaria.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Bulgaria-Passport_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Bulgaria-Passport_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Cambodia-Passport": {
    "ImageSize": "35x45 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "826x1062",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Cambodia.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Cambodia-Passport_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Cambodia-Passport_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Canada-Passport": {
    "ImageSize": "50x70 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "2000x2800",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Canada.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Canada-Passport_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Canada-Passport_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Canada-Citizenship": {
    "ImageSize": "50x70 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "2000x2800",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Canada.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Canada-Citizenship_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Canada-Citizenship_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Canada-Permanent Resident": {
    "ImageSize": "50x70 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "2000x2800",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Canada.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Canada-PermanentResident_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Canada-PermanentResident_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Canada-Visa": {
    "ImageSize": "35x45 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "826x1062",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Canada.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Canada-Visa_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Canada-Visa_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "China-Passport": {
    "ImageSize": "33x48 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "385x560",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/China.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/China-Passport_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/China-Passport_d.jpeg",
    "hair_to_chin_perc": 0.685
  },
  "China-Visa": {
    "ImageSize": "2x2 in",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "600x600",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/China.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/China-Visa_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/China-Visa_d.jpeg",
    "hair_to_chin_perc": 0.685
  },
  "Colombia-Passport": {
    "ImageSize": "1.5x2.5 in",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "900x1500",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Colombia.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Colombia-Passport_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Colombia-Passport_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Congo-Passport": {
    "ImageSize": "2x2 in",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "1200x1200",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Congo.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Congo-Passport_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Congo-Passport_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Congo-Visa": {
    "ImageSize": "2x2 in",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "1200x1200",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Congo.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Congo-Visa_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Congo-Visa_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Costa Rica-Passport": {
    "ImageSize": "35x45 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "826x1062",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/CostaRica.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/CostaRica-Passport_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/CostaRica-Passport_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Croatia-Passport": {
    "ImageSize": "35x45 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "826x1062",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Croatia.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Croatia-Passport_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Croatia-Passport_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Cyprus-Passport": {
    "ImageSize": "40x50 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "944x1181",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Cyprus.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Cyprus-Passport_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Cyprus-Passport_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Czech Republic-Passport": {
    "ImageSize": "35x45 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "826x1062",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/CzechRepublic.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/CzechRepublic-Passport_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/CzechRepublic-Passport_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Denmark-Passport": {
    "ImageSize": "35x45 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "826x1062",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Denmark.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Denmark-Passport_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Denmark-Passport_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Estonia-Passport": {
    "ImageSize": "40x50 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "2600x3250",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Estonia.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Estonia-Passport_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Estonia-Passport_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Finland-Passport": {
    "ImageSize": "36x47 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "500x653",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Finland.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Finland-Passport_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Finland-Passport_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Finland-Visa": {
    "ImageSize": "36x47 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "500x653",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Finland.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Finland-Visa_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Finland-Visa_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "France-Passport": {
    "ImageSize": "35x45 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "826x1062",
    "background_color": "211,211,211",
    "background_color_modal": "Gray",
    "image_path_passport": "/images/passport_imgs/France.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/France-Passport_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/France-Passport_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "France-Visa": {
    "ImageSize": "35x45 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "826x1062",
    "background_color": "211,211,211",
    "background_color_modal": "Gray",
    "image_path_passport": "/images/passport_imgs/France.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/France-Visa_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/France-Visa_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Germany-Passport": {
    "ImageSize": "35x45 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "826x1062",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Germany.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Germany-Passport_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Germany-Passport_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Ghana-Passport": {
    "ImageSize": "50x50 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "1181x1181",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Ghana.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Ghana-Passport_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Ghana-Passport_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Greece-Passport": {
    "ImageSize": "40x60 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "944x1417",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Greece.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Greece-Passport_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Greece-Passport_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Hong Kong-Passport": {
    "ImageSize": "40x50 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "1600x2000",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/HongKong.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/HongKong-Passport_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/HongKong-Passport_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Hungary-Passport": {
    "ImageSize": "35x45 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "826x1062",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Hungary.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Hungary-Passport_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Hungary-Passport_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "India-Passport 5.1 x 5.1 cm": {
    "ImageSize": "2x2 in",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "600x600",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/India.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/India-Passport5.1x5.1cm_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/India-Passport5.1x5.1cm_d.jpeg",
    "hair_to_chin_perc": 0.685
  },
  "India-Passport 3.5 x 3.5 cm": {
    "ImageSize": "35x35 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "600x600",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/India.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/India-Passport3.5x3.5cm_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/India-Passport3.5x3.5cm_d.jpeg",
    "hair_to_chin_perc": 0.685
  },
  "India-Visa": {
    "ImageSize": "2x2 in",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "600x600",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/India.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/India-Visa_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/India-Visa_d.jpeg",
    "hair_to_chin_perc": 0.685
  },
  "India-Overseas Citizenship 51 x 51 mm": {
    "ImageSize": "2x2 in",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "600x600",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/India.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/India-OverseasCitizenship51x51mm_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/India-OverseasCitizenship51x51mm_d.jpeg",
    "hair_to_chin_perc": 0.685
  },
  "India-Overseas Citizenship": {
    "ImageSize": "35x35 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "600x600",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/India.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/India-OverseasCitizenship_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/India-OverseasCitizenship_d.jpeg",
    "hair_to_chin_perc": 0.685
  },
  "India-PAN Card": {
    "ImageSize": "25x35 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "590x826",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/India.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/India-PANCard_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/India-PANCard_d.jpeg",
    "hair_to_chin_perc": 0.685
  },
  "Indonesia-Passport": {
    "ImageSize": "30x40 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "708x944",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Indonesia.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Indonesia-Passport_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Indonesia-Passport_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Iran-Passport": {
    "ImageSize": "30x40 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "708x944",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Iran.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Iran-Passport_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Iran-Passport_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Israel-Passport": {
    "ImageSize": "35x45 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "826x1062",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Israel.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Israel-Passport_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Israel-Passport_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Italy-Passport": {
    "ImageSize": "35x45 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "826x1062",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Italy.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Italy-Passport_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Italy-Passport_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Jamaica-Passport": {
    "ImageSize": "2x2 in",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "1200x1200",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Jamaica.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Jamaica-Passport_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Jamaica-Passport_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Japan-Passport": {
    "ImageSize": "35x45 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "826x1062",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Japan.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Japan-Passport_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Japan-Passport_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Japan-Visa": {
    "ImageSize": "35x45 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "826x1062",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Japan.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Japan-Visa_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Japan-Visa_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Japan-Driving License": {
    "ImageSize": "24x30 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "566x708",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Japan.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Japan-DrivingLicense_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Japan-DrivingLicense_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Japan-International Driving License": {
    "ImageSize": "35x45 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "826x1062",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Japan.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Japan-InternationalDrivingLicense_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Japan-InternationalDrivingLicense_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Japan-Alien Registration-Professional": {
    "ImageSize": "30x40 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "708x944",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Japan.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Japan-AlienRegistration-Professional_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Japan-AlienRegistration-Professional_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Kenya-Passport": {
    "ImageSize": "51x51 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "1204x1204",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Kenya.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Kenya-Passport_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Kenya-Passport_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Lebanon-Passport": {
    "ImageSize": "2x2 in",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "1200x1200",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Lebanon.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Lebanon-Passport_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Lebanon-Passport_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Macao-Passport": {
    "ImageSize": "35x45 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "826x1062",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Macao.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Macao-Passport_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Macao-Passport_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Malaysia-Passport": {
    "ImageSize": "35x50 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "1050x1500",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Malaysia.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Malaysia-Passport_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Malaysia-Passport_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Malaysia-Visa": {
    "ImageSize": "35x50 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "1050x1500",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Malaysia.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Malaysia-Visa_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Malaysia-Visa_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Mexico-Passport": {
    "ImageSize": "35x45 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "826x1062",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Mexico.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Mexico-Passport_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Mexico-Passport_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Morocco-Passport": {
    "ImageSize": "35x45 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "826x1062",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Morocco.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Morocco-Passport_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Morocco-Passport_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Myanmar-Passport": {
    "ImageSize": "35x45 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "826x1062",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Myanmar.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Myanmar-Passport_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Myanmar-Passport_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Myanmar-Visa": {
    "ImageSize": "35x45 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "826x1062",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Myanmar.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Myanmar-Visa_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Myanmar-Visa_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Netherlands-Passport": {
    "ImageSize": "35x45 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "826x1062",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Netherlands.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Netherlands-Passport_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Netherlands-Passport_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Nigeria-Passport": {
    "ImageSize": "35x45 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "700x900",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Nigeria.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Nigeria-Passport_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Nigeria-Passport_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Norway-Passport": {
    "ImageSize": "35x45 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "826x1062",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Norway.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Norway-Passport_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Norway-Passport_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Pakistan-Passport": {
    "ImageSize": "35x45 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "826x1062",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Pakistan.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Pakistan-Passport_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Pakistan-Passport_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Panama-Passport": {
    "ImageSize": "35x45 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "826x1062",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Panama.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Panama-Passport_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Panama-Passport_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Peru-Passport": {
    "ImageSize": "2x2 in",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "1200x1200",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Peru.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Peru-Passport_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Peru-Passport_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Philippines-Passport": {
    "ImageSize": "35x45 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "826x1062",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Philippines.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Philippines-Passport_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Philippines-Passport_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Poland-Passport": {
    "ImageSize": "35x45 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "826x1062",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Poland.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Poland-Passport_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Poland-Passport_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Portugal-Passport": {
    "ImageSize": "35x45 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "826x1062",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Portugal.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Portugal-Passport_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Portugal-Passport_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Romania-Passport": {
    "ImageSize": "35x45 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "826x1062",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Romania.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Romania-Passport_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Romania-Passport_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Russia-Passport": {
    "ImageSize": "35x45 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "826x1062",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Russia.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Russia-Passport_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Russia-Passport_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Saudi Arabia-Passport": {
    "ImageSize": "2x2 in",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "1200x1200",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/SaudiArabia.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/SaudiArabia-Passport_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/SaudiArabia-Passport_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Saudi Arabia-Visa": {
    "ImageSize": "2x2 in",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "1200x1200",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/SaudiArabia.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/SaudiArabia-Visa_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/SaudiArabia-Visa_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "South Africa-Passport": {
    "ImageSize": "2x2 in",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "1200x1200",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/SouthAfrica.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/SouthAfrica-Passport_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/SouthAfrica-Passport_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "South Korea-Passport": {
    "ImageSize": "35x45 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "826x1062",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/SouthKorea.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/SouthKorea-Passport_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/SouthKorea-Passport_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Spain-Passport": {
    "ImageSize": "30x40 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "708x944",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Spain.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Spain-Passport_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Spain-Passport_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Sweden-Passport": {
    "ImageSize": "35x45 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "826x1062",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Sweden.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Sweden-Passport_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Sweden-Passport_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Switzerland-Passport": {
    "ImageSize": "35x45 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "826x1062",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Switzerland.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Switzerland-Passport_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Switzerland-Passport_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Syria-Passport": {
    "ImageSize": "40x40 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "944x944",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Syria.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Syria-Passport_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Syria-Passport_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Taiwan-Passport": {
    "ImageSize": "35x45 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "826x1062",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Taiwan.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Taiwan-Passport_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Taiwan-Passport_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Tanzania-Passport": {
    "ImageSize": "40x45 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "944x1062",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Tanzania.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Tanzania-Passport_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Tanzania-Passport_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Tanzania-Visa": {
    "ImageSize": "2x2 in",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "600x600",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Tanzania.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Tanzania-Visa_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Tanzania-Visa_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Thailand-Passport": {
    "ImageSize": "35x45 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "826x1062",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Thailand.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Thailand-Passport_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Thailand-Passport_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Thailand-Visa": {
    "ImageSize": "40x60 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "944x1417",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Thailand.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Thailand-Visa_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Thailand-Visa_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Trinidad and Tobago-Passport": {
    "ImageSize": "31x41 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "732x968",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/TrinidadandTobago.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/TrinidadandTobago-Passport_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/TrinidadandTobago-Passport_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Turkey-Passport": {
    "ImageSize": "35x45 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "826x1062",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Turkey.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Turkey-Passport_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Turkey-Passport_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Turkey-Visa": {
    "ImageSize": "50x50 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "1181x1181",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Turkey.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Turkey-Visa_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Turkey-Visa_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Ukraine-Passport": {
    "ImageSize": "35x45 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "826x1062",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Ukraine.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Ukraine-Passport_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Ukraine-Passport_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Uzbekistan-Passport": {
    "ImageSize": "35x45 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "826x1062",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Uzbekistan.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Uzbekistan-Passport_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Uzbekistan-Passport_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Venezuela-Passport": {
    "ImageSize": "2x2 in",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "1200x1200",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Venezuela.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Venezuela-Passport_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Venezuela-Passport_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Vietnam-Passport": {
    "ImageSize": "40x60 mm",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "600x900",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Vietnam.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Vietnam-Passport_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Vietnam-Passport_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Vietnam-Visa": {
    "ImageSize": "2x2 in",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "900x900",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Vietnam.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Vietnam-Visa_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Vietnam-Visa_d.jpeg",
    "hair_to_chin_perc": 0.73
  },
  "Zimbabwe-Passport": {
    "ImageSize": "2x2 in",
    "SheetSize": "4x6 in",
    "dpi": 600,
    "digital_dims": "1200x1200",
    "background_color": "255,255,255",
    "background_color_modal": "White",
    "image_path_passport": "/images/passport_imgs/Zimbabwe.webp",
    "image_path_org": "/images/passport_imgs/modal_original.jpg",
    "image_path_p_guidelines": "/images/passport_imgs/Zimbabwe-Passport_p.jpeg",
    "image_path_d_guidelines": "/images/passport_imgs/Zimbabwe-Passport_d.jpeg",
    "hair_to_chin_perc": 0.73
  }
};
